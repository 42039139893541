.map {
  height: 100%;

  .map-row{
    width: 100%;
    height: 100%;
    position: relative;
  }

}
