@import "../../../styles/variables";

.tooltip-button {
  width: 20px;
  height: 20px;
  color: $color-blue-dark;
  font-size: 13px;
  text-align: center;
  background-color: $color-yellow;
  border-radius: 3px;
  margin: 0 15px;
}
