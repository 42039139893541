
.page-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &.content-paddings {
    padding: 20px 42px;
  }
}
