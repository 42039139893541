.split-cmp {
  position: relative;
}

.split-btn {
  display: inline-flex;
  position: relative;

  .button-text {
    flex-grow: 1;
    font-family: 'Maven', sans-serif;
    font-size: 18px!important;
    font-weight: 500;
    line-height: 1.15;
    word-break: break-all;
  }
}


.icon-rotate {
  transform: rotate(360deg)!important;
}
.btn {
  background: #ffd500;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all 0.2s ease;
  will-change: transform;
  padding: 15px 20px;
  color: #1a1600;
  border-radius: 10px;
  position: relative;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  border: 0;

}

.btn > span {
  flex-grow: 1;

  .button-text {
    flex-grow: 1;
    font-family: 'Maven', sans-serif;
    font-size: 18px!important;
    font-weight: 500;
    line-height: 1.15;
    word-break: break-all;
  }
}

.btn.assign-btn {
  width: 330px;
}

.split-btn > *:first-child {
  height: 100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  &.bottom-button-visible-popover{
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
}
.split-btn > *:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  &.bottom-button-visible-popover{
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
}

.split-btn__separator {
  width: 1.5px;
}

.padding-x-xs {
  padding-left: 0.75rem ;
  padding-right: 0.75rem;
}
/* popover */
.split-btn-popover {
  width: 100%;
  background-color: hsl(180, 3%, 100%);
  padding: 0.5625rem 0;
  border-radius: 10px;
  box-shadow: inset 0 0 0.5px 1px hsla(0, 0%, 100%, 0.075);
}

.split-arrow {
  margin-left: 1px;
}

.popover {
  width: 330px;
  z-index: 5;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  visibility: hidden;
  opacity: 0;
  padding: 0;
  margin: 0;
  margin-bottom: 2px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  transition: visibility 0s var(--popover-transition-duration),opacity var(--popover-transition-duration);
}
.split-btn-popover__list {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 330px;

  li {
    border-radius: 0;

    &:last-child {
      border-top-right-radius: 10px;
    }
  }
}
.icon {
  height: 12px;
  width: 12px;
  display: inline-block;
  color: inherit;
  fill: currentColor;
  line-height: 1;
  flex-shrink: 0;
  max-width: initial;
  transform: rotate(180deg);
}
.popover--is-visible {
  visibility: visible;
  opacity: 1;
}
.split-btn-popover__item {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 15px 20px;
  margin-top: 2px;
  cursor: pointer;
  transition: 0.2s;
  background: #ffd500;
  color: #1a1600;
  border: none;

  .button-text {
    flex-grow: 1;
    font-family: 'Maven', sans-serif;
    font-size: 18px!important;
    font-weight: 500;
    line-height: 1.15;
    word-break: break-all;
  }
}

.split-btn-popover__item span {
  flex-grow: 1;
}
.opacity-50\% {
  opacity: .5;
}
.disabled {
  opacity: 0.5;
}
