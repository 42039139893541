.license-selector {
  position: relative;
}
.license-selector .arrow {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translate(0%,-50%);
  right: 5px;
  color: #ffd500;
}

.license-selector .arrow.rotate {
  transform: translate(0%, -50%) rotate(180deg) ;
}

.select-deactive .select__options {
  background: #f4f5f7;
  max-height: 0px;
  opacity: 0;
}

.select-active .select__options {
  max-height: 180px;
  opacity: 1;
}

.select__options {
  background: #f4f5f7;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  transition: opacity 0.2s, padding 0.2s, max-height 0.2s;
  overflow-y: auto;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
  z-index: 999;
}

.select__options .select__option {
  cursor: pointer;
  padding: 13px;
  trasition: all 0.2s;
}


.select__options .select__option:hover {
  background: rgba(0, 0, 0, 0.1);
}
