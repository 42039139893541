.invoice-card {
  background-color: #fff;
  margin-bottom: 10px;
  border-radius: 7px;
}

.invoice-card .card .card-inner .top-section .left-section {
  width: 100%;
}

/** unread invoices has bold elements **/
.invoice-card.invoice-unread .card .invoice-main-content{
  font-weight: 500;
}
/** unread invoices has bold elements **/
.invoice-card.invoice-unread .card .right-section {
  font-weight: 500;
}

.invoice-card .card .invoice-avatar .img-container {
  background-color: #c9c9c9;
  padding: 7px;
}
/** unread invoices has bold elements **/
.invoice-card.invoice-unread .invoice-avatar .img-container .invoice-new-icon {
  color: #E84D0E;
  font-weight: 500;
}

.invoice-card .card .invoice-main-content {
  color: #00587f;
  font-size: 16px;
  font-weight: 300;
  line-height: 18px;
}

.invoice-card.invoice-status-green {
  border-right: 5px solid #95C11E;
}
.invoice-card.invoice-status-yellow {
  border-right: 5px solid #FFD500;
}
