@import "../../../styles/variables";

.chart-container {
  width: 100%;
  height: 30vw;
  max-width: 100vw;
  min-height: 30vw;
  border-radius: 3px;
  //overflow-y: auto;
}

.licence-plate-table-cell {
  width: 100px;
  padding: 5px;
}

@media (max-width: 1200px) {
  .chart-container {
    height: 40vw;
  }
}
