@import "../../../styles/variables";

.car-section {
  .card {
    position: relative;

    .icon-set {
      height: unset !important;
      max-height: unset !important;
      min-height: unset !important;
      margin: 8px 0 !important;
    }

    .card-inner {
      flex-direction: column;
      padding: 8px 8px 8px;
    }
  }

  .card:after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00273c !important;
    z-index: -3;
  }

  .type-green {
    border-right: 5px solid #95c11e;
  }

  &.car-open {
    .card {
      background: #004464;
    }
  }

  .type-red {
    border-right: 5px solid #e84d0e;
  }

  .user-avatar {
    position: relative;

    .img-container {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      overflow: hidden;
      position: relative;
      border-radius: 50%;
      min-width: 64px;
      width: 64px;
      height: 64px;
      background: $color-white;
      justify-content: center;
      align-items: center;

      &.small {
        width: 50px;
        height: 50px;
        min-width: 50px;

        span {
          font-size: 22px;
        }
      }

      &:after {
        content: "";
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        padding-bottom: 100%;
      }

      > img {
        object-fit: cover;
        position: absolute;
        width: 100%;
        height: 100%;
      }

      > svg {
        height: 100%;
        width: auto;
        padding: 2px;
        fill: $color-white;
        background-color: #c9c9c9;
      }

      span {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        color: $color-blue-dark;
        font-size: 30px;
        font-weight: 100;
        user-select: none;
      }
    }

    .icon-container {
      position: absolute;
      right: 0;
      bottom: 0;
      border: none;
      overflow: hidden;
      background: transparent;
      width: 16px;
      height: 16px;

      svg {
        height: 18px;
        width: auto;
        display: block;
      }

      &.small {
        svg {
          height: 18px;
        }
      }

      &.has-background-color {
        padding: 2px;
      }

      &.transparent {
        background-color: transparent;
      }

      &.green {
        background-color: $color-green;
      }

      &.white {
        background-color: $color-white;
      }

      &.black {
        background-color: $color-black;
      }

      &.red {
        background-color: $color-red;
      }

      &.blue {
        background-color: $color-blue-middle;
      }

      &.yellow {
        background-color: $color-yellow;
      }
    }
  }

  .vehicles {
    .icon-set {
      padding: 0 !important;
    }
  }

  .check {
    width: 18px !important;
    font-weight: bold;
  }

  .sub-text {
    font-size: 12px !important;
  }

  .tags {
    color: $color-yellow;
    font-size: 12px;
    white-space: normal;
  }

  .bottom-section .description {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
    display: flex;
    border-top: 1px solid #fff;
  }

  .bottom-section .description .info-left,   .bottom-section .description .info-right  {
    height: 44px;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    display: flex;
  }

  .bottom-section .description .info-right {
    color: #ffd500;
  }

  .vehicle-inspection-and-history {
    .inspection-container {
    font-size: 14px;
    margin-bottom: 10px;

      .inspection-left {
        //margin-right: 25px;
      }
    }
  }
}
