@import "../../../styles/variables";

.ticket-email-container {
  height: 80vh;
  padding-top: 20px;
  display: flex;
  flex-grow: 0;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  justify-content: space-between;

  .form-close-button{
    position: absolute;
    top: 10px;
    right: 0;
  }

  .email-content {
    .email-header-input-container {
      max-width: 400px;
      display: flex;
      p {
        text-transform: capitalize;
        width: 30%
      }
      input {
        border: none;
        background-color: transparent;
        color: gray;
      }
      padding: 5px;
      border-bottom: 1px solid lightgrey;
    }
    textarea.email-body {
      width: 100%;
      background-color: $color-white-light;
      border: none;
      border-radius: 5px;
      padding: 20px 10px;
      margin-top: 30px;

      font-family: 'Maven', sans-serif;
      font-size: 18px !important;
      font-weight: 400;
      line-height: 1.15;
      word-break: break-all;
    }
  }
  .send-button {
    .button-text {
      color: black;
      font-family: 'Maven', sans-serif;
      font-size: 18px !important;
      font-weight: 500;
      line-height: 1.15;
      word-break: break-all;
    }
  }
}
