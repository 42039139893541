@import "../../../styles/variables";

.service{
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background: $color-blue-black;
  margin-bottom: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  padding: 6px 4px;
  border-right: 5px solid $color-yellow;
  margin-left: -4px;
  margin-right: -4px;
  height: 96px;
  min-height: 96px;
  &:hover{
    cursor: pointer;
  }

  .name{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    width: 266px;
    padding-left: 3px;
  }

  .name img{
    max-width: 40px;
    max-height: 40px;
  }

  .brands{
    margin-left: -5px;
    max-width: 90px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    text-align: center;
    align-items: center;
  }

  .brand-default{
    display: inline-block;
    border-radius: 50%;
    background: $color-white-dark;
    width: 42px;
    padding: 8px 4px 4px 4px;
    svg{
      transform: scale(-1, 1)
    }
  }

  .stars{
    display: flex;
    justify-content: flex-end;
  }

  .data{
    margin-top: -1px;

    .mdi-icon{
      color: $color-yellow;
      width: 12px;
    }
  }

  .info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 4px;
    max-width: 190px;
    padding-right: 5px;
    flex-wrap: wrap;
  }

  .title{
    font-size: 16px;
    line-height: 18px;
    max-height: 40px;
    overflow: hidden;
  }

  .street{
    font-size: 12px;
    margin-top: 2px;
  }

  .icons{
    text-align: right;
    padding-right: 2px;
  }

  .icons .mdi-icon{
    color: $color-white;
    width: 16px;
    height: 18px;
    margin-left: 3px;
  }

  .icons-img {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: 1px;
    margin-bottom: 5px;

    img {
      color: $color-white;
      max-width: 14px;
      max-height: 18px;
      margin-left: 4px;
      margin-bottom: 4px;
    }

    .misc {
      max-width: 24px;
      max-height: 25px;
      margin-top: -7px;
    }
  }

  .city{
    font-size: 11px;
    margin-top: -2px;
  }

  .km{
    flex-direction: row;
    color: $color-yellow;
    position: relative;
    display: inline-block;

    .mdi-icon {
      color: $color-yellow;
      position: absolute;
      left: -20px;
      width: 15px;
      bottom: 2px;
    }
  }
}
