@import "../../../../styles/variables";

.employee-damages-list{
  overflow-y: auto;
  height: calc(100vh - 230px);
  width: 40vw;

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

.employee-damages-list-container {
  display: flex;
  flex-direction: row;
  position: relative;
  padding-left: 30px;

  .empty-vehicle-tab{
    text-align: center;
    font-size: 18px;
    display: block;
    border-radius: 1px;
    max-width: 320px;
    background: #E84D0E;
    padding: 3px 4px 5px 4px;
    line-height: 20px;
    color: #fff;
  }

  .data-form {
    padding: 40px;
    flex-basis: 50%;
    word-break: break-word;

    .form-value {
      word-break: break-word;
    }
  }

  .registration-link{
    color: $color-blue-middle;
    font-size: 16px;
    text-decoration: underline;
  }

  .form-name{
    color: $color-blue-middle;
    font-size: 16px;
  }

  .form-value{
    color: $color-blue-dark;
    font-size: 16px;
  }

  .map {
    overflow: hidden;
    height: calc(100vh - 190px);
    max-width: 650px;
  }

  .map-row{
    width: 100%;
    height: 100%;
    position: relative;
  }

  .map-row-none{
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
  }

  .car-icon {
    background: $color-white !important;
    width: 25px;
    height: 25px;
    z-index: 4 !important;
  }

  .car-icon > img {
    max-width: 26px !important;
    width: 26px !important;
    z-index: 4 !important;
    position: relative;
  }

  .license-plate-icon {
    position: absolute;
    height: 22px;
    top: 0;
    background: $color-white;
    left: 21px;
    z-index: 3 !important;
    width: 98px !important;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #00587F;
    padding: 0 3px 0 8px;
    max-width: 66px !important;
    line-height: 22px;
    border-radius: 4px;
  }

}
