@import "../../../styles/variables";

.chart-container {
  width: 100%;
  height: 40vw!important;
  max-width: 100vw;
  border-radius: 3px;
  //overflow-y: auto;
}

.tooltip-header {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .tooltip-button {
    margin: 5px 0;
    border: 1px solid white;
    font-size: 11px;
  }
}

.debounce-input-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  span {
    padding-left: 10px;
  }

  input {
    text-align: right;
    padding-top: 11px;
    padding-bottom: 10px;
    border: 1px lightgrey solid;
    border-radius: 5px;
    width: 90px;
  }
}

.data-sum-row{
  font-size: 16px;
  font-weight: bold;
}

.table-input {
  width: 75px!important;
  background-color: $color-yellow-high-light;
  border: 1px solid $color-grey-light;
  border-radius: 3px;
  text-align: right;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.licence-plate-table-cell {
  width: 90px!important;
  padding: 5px;
}

@media (max-width: 1200px) {
  .chart-container {
    height: 40vw;
  }
}
