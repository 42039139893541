@import "../../../../styles/variables.scss";

$base-font-size: 16px;
$container-padding: 25px 40px;
$text-paragraph-margin: 0 15px 0 15px;

@mixin flex-display-column {
  display: flex;
  flex-direction: column;
}

.reservation-details-container {
  @include flex-display-column;
  justify-content: space-between;
  height: 100%;
  padding: $container-padding;

  .reservation-info {
    background: #fffcd6;
    color: $color-yellow;
    border: 1px solid $color-yellow;
    border-radius: 8px;
    font-size: 18px;
    max-width: 400px;
    line-height: 20px;
    padding: 10px 14px 10px 2px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: fit-content;
    width: fit-content;

    p {
      display: flex;
      align-items: center;
      margin: $text-paragraph-margin;
    }
  }
}

.reservation-detailes-rows {
  @include flex-display-column;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 400px;

  .single-information-row {
    display: flex;
    font-size: $base-font-size;
    padding: 5px 0;

    :first-child {
      width: 150px;
      display: block;
      font-size: $base-font-size;
      color: $color-blue-middle;
      margin-right: 10px;
    }

    :last-child {
      font-size: $base-font-size;
    }
  }
}

.reservation-billing-rows {
  .single-billing-row {
    display: flex;
    justify-content: space-between;
    font-size: $base-font-size;
    padding: 5px 0;

    :first-child {
      font-size: $base-font-size;
      color: $color-blue-middle;
      margin-right: 10px;
    }

    :last-child {
      font-size: $base-font-size;
    }

    &.row-with-border {
      border-top: 1px solid $color-grey-dark;
    }

    &.balance {
      font-weight: 500;
      margin-top: 40px;

      > * {
        font-size: 18px !important;
      }
    }
  }
}
