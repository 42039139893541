.tracking-frame {
  display: flex;
  color: #00587F;
  margin-top: 20px;

  .left-block {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-between;
    max-width: 130px;

    > div {
      display: flex;
    }

    .time-span {
      display: inline-block;
      width: 42px;
    }

    img {
      width: 70px;
      margin-left: 7px;
    }

    .recorded-time {
      display: flex;
      align-content: center;

      span {
        margin-left: 3px;
      }
    }
  }

  .right-block {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    margin-left: 8px;

    .tracking-address {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .start-stop {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-right: 20px;
    }
  }
}
