@import "../../../styles/variables";

.pdf-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 50vh;
  padding-top: 20px;
  .pdf-preview {
    border: 1px solid lightgrey;
  }
}

.ticket-image-container{
  padding-top: 20px;
  display: flex;
  gap: 5%;
  img {
    width: 40%;
  }
}

