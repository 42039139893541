@import "../../../styles/variables";

.notice-block {
  font-size: 14px;
  line-height: 1.1;
  padding: 8px;
  color: $color-blue-middle;
  border: 2px solid $color-blue-middle;
  border-radius: 16px;
  background-color: $color-white-light;
  a{
    color: $color-blue-middle;
    font-size: 14px;
    line-height: 1.1;
    &:hover{
      color: $color-blue-middle;
      font-size: 14px;
      line-height: 1.1;
      text-decoration: underline;
    }
  }

  svg {
    display: block;
    height: 24px;
    width: auto;
  }
}
