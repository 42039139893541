@import "../../../styles/variables";

.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background: rgba($color-blue-dark, .95);
  z-index: 999999;

  .modal-container {
    display: flex;
    padding: 24px;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .modal-header {
      display: flex;
      align-self: flex-end;


      .close-button {
        padding: 0;

        svg {
          width: 50px;
        }

        svg {
          fill: $color-white;
        }
      }
    }

    .modal-content {
      display: flex;
      flex-grow: 1;
      overflow: hidden;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 90%;
      height: 100%;
      padding-bottom: 50px;
      max-width: 90%;


      img {
        height: auto;
        max-width: 100%;
        max-height: 600px;
        width: auto;
      }

      .loader-image{
        width: auto;
      }
    }

    .modal-footer {
      display: flex;
      flex: 0;
      width: 100%;
    }
  }
}
