.search-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  width: 368px;
  position: relative;
  padding: 5px 6px;
}
.search-input > input {
  width: 100%;
  box-shadow: 0 3px 6px #00000029;
  border-radius: 23px !important;
  opacity: 1;
  color: black;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  background-color: rgba(0, 88, 127, 0.35);
  border: 1px solid rgba(0, 0, 0, 0.05);
  padding: 8px 8px 8px 40px;
  font-size: 18px;
}

.search-input > input[type="text"]::placeholder {
  padding-left: 10px;
  color: black;
}

.search-bar svg:first-of-type {
  z-index: 2;
  left: 16px;
}

.search-bar svg {
  position: absolute;
}
