
.sidebar {
  position: fixed;
  z-index: 99999;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: auto;
  top: 0;
  bottom: 0;
  width: 56px;
  background: rgba(#00587f, 0.3);
  transition: width 0.3s ease-in-out;
  overflow: hidden;
}

.sidebar li {
  list-style: none;
  color: #fff;
  font-size: 20px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 5px;
}
.sidebar li span {
  display: inline;
  padding-left: 10px;
  font-size: 20px;
  opacity: 0;
  width: 0;
  height: auto;
}

.sidebar.expand {
  width: 200px;
  background: linear-gradient(20deg, #00273C 0%, #00273C 25%, #00587F 100%);

  .header-profile-user {
    opacity: 1;
    width: auto;
  }

  li span {
    width: 100%;
    opacity: 1;
  }

  .bottom-actions {
    border: 2px solid #FFD500;
    border-radius: 10px;
    .label {
      opacity: 1;
      width: 100%;
    }
  }

}


.sidebar li {
  span {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
}
