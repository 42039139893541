.split-button-container {
  border-radius: 10px;
  display: flex;
  height: 50px;
  width: 100%;
  min-width: 200px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  button {
    border: none;
    &:hover {
      cursor: pointer;
    }
    p {
      font-family: 'Maven', sans-serif;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.15;
      word-break: break-all;
      margin-left: 10px;
    }

    &.split-button-left {
      border-radius: 10px 0 0 10px;
      border-right: 2px solid white;
      margin: 0;
      height: 100%;
      flex: 1;

      &.is-primary {
        background: linear-gradient(to right, #8fc21e, #71c122);
        color: white;
      }

      &.is-link {
        background: linear-gradient(to right, #00557b, #00293d);
        color: white;
      }

      &.is-danger {
        background: linear-gradient(to right, #e8490f, #e82a0e);
        color: white;
      }

      &.is-warning {
        background-color: #ffd500;
      }
      &.is-success {
        background: linear-gradient(to right, #91c11e, #91c11e);
      }
      &.apply-btn {
        background: black;
        margin: 1200px;
      }
      &.disabled {
        opacity: 0.5;
      }
    }

    &.split-button-right {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 10px 10px 0;
      border-left: 2px solid white;
      margin: 0;
      height: 100%;
      flex: 2;
      &.is-primary {
        background: linear-gradient(to left, #8fc21e, #71c122);
      }

      &.is-link {
        background: linear-gradient(to left, #00557b, #00293d);
        color: white;
      }

      &.is-danger {
        background: linear-gradient(to left, #e8490f, #e82a0e);
        color: white;
      }

      &.is-warning {
        background-color: #ffd500;
      }
      &.is-success {
        background: linear-gradient(to left, #91c11e, #91c11e);
        color: white;
      }
      &.apply-btn {
        background: black;
        margin: 1200px;
      }
      &.disabled {
        opacity: 0.5;
      }
    }


  }
}
