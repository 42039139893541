@import "../../../styles/variables";

.card-container {
  background-color: white;
  -webkit-box-shadow: 8px 8px 12px 3px rgba(88,127,100,0.19);
  -moz-box-shadow: 8px 8px 12px 3px rgba(88,127,100,0.19);
  box-shadow: 8px 8px 12px 3px rgba(88,127,100,0.19);
  margin-bottom: 15px;
  border-radius: 3px;

  .reporting-card-header{
    min-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    background-color: $color-blue-middle;
    color: white;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
}
