@import "../../../styles/variables";

.toggler {
  display: flex;
  &:hover {
    cursor: pointer;
  }
}


.toggler .react-toggle-track {
  width: 24px;
  height: 8px;
}

.toggler{
  margin-top: 9px;
}

.toggler .react-toggle-thumb {
  width: 12px;
  height: 12px;
  top: -2px;
  left: -1px;
}


.toggler .react-toggle--checked .react-toggle-thumb {
  left: 14px;
  border-color: $color-yellow;
  background-color: $color-yellow;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.41);
}

.toggler .react-toggle--checked .react-toggle-track {
  background-color: $color-yellow;
}

.toggler .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  border-color: $color-yellow;
};

.toggler .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track{
  background-color: $color-yellow !important;
}

.setting .toggler .react-toggle--checked .react-toggle-thumb {
  left: 16px;
  border-color: $color-yellow;
}
