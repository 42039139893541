.grey-area{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    z-index: 999999;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.4);
}

.add-driver-container {
    position: absolute;
    top: 0;
    left: 0;
    background:white;
    max-width: 360px;
    border-radius: 20px;
    border-style: solid;
    border-color: #2d7695;
    z-index: 999999;
    padding: 10px 15px 15px 20px;
    border-width: 2px;

    .driver-data{
        margin-top: 10px;
        margin-bottom: 10px;
        color: #00587f;

    }

    .driver-form {
        justify-content: center;
        align-content: center;
        display: flex;
        flex-direction: column;

        .input > input{
            font-size: 16px;
        }

        .form-field{
            div .input.full p{
                        display: none;

            }
            .cmp-date-picker{
                .calendar{
                    border-radius: 5px;
                    height: 34px;
                    margin-top: 10px;
                }
                p{display: none}
                .react-datepicker__input-container{
                    //width: 86%;
                    border-radius: 5px;
                }
            }
        }

        > .button {
            margin-left: 30px;
            margin-right: 30px;
            width: auto;
            padding: 0 30px;
        }
    }

    > .close-button {
        margin-top: 10px;
        margin-right: 10px;
    }
}

.add-driver-container .input-label {
    padding-top: 5px;
}

.add-driver-container .close-button {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
}

.add-driver-container .close-button svg {
    width: 25px;
    height: 25px;
    object-fit: contain;
}

.add-driver-container .label.picker-label {
    padding-top: 5px;
    color: #00587f;
}


.add-driver-container button.button {
    margin-top: 15px;
    color: #fff;
}
