.trips-list.list-container {
  height: calc(100vh - 555px);
  align-items: flex-start;
  max-width: 500px;
  overflow: auto;
}

.single-trip-container {
  color: #00587F;
  min-height: 120px;
  position: relative;
  overflow: hidden;
  transition: height 0.2s ease;
  padding: 10px 10px 10px 0;
  &.first {
    min-height: 80px;
  }
}

.single-trip-container:hover {
  cursor: pointer;
}

.single-trip-container.trip-expanded {

  padding: 10px 10px 10px 0;
  background: #edf5f8;
  width: 500px;

  &.first {
    min-height: 80px;
  }
}

.single-trip-container .trip-actions {
  opacity: 0;
}

.single-trip-container.trip-expanded > .trip-actions{
  opacity: 1;
}


