@import "../../styles/variables";

.main-container {
  position: relative;
  background-color: $color-white-light;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.stat-container {
  padding: 30px 100px 30px 165px;
  //background-color: white;
}

.card-list {
  min-height: 100vh;
}

.licence-plate-container {
  margin: 0 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.selected-vehicle {
    border-bottom: 3px solid $color-green;
  }
}

.customer-name {
  font-size: 25px;
  font-weight: 500;
}

.customer-number {
  font-size: 17px;
}

.loading-overlay {
  position: fixed;
  z-index: 1000000;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 50, 0.3);
}

.card-header-title {
  display: flex;
  align-items: center;
  color: white;
  font-size: 17px;
  font-weight: 500;
}

