@import "../../../../styles/variables";

.employee-cc-list {
  overflow-y: auto;
  height: calc(100vh - 230px);
  width: 40vw;

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}


.no-data {
  max-width: 650px !important;
  display: inline-block;
  width: 100%;
  position: relative;

  p{
    max-width: 505px !important;
    font-size: 40px;
    font-family: 'Maven', sans-serif;
    font-weight: 500;
    color: #919191;
    position: absolute;
    left: 50%;
    top: -80px;
    width: 100%;
    transform: translate(-85%, -10px);
  }

  img{
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
  }

}
