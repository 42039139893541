.upload-cmp {
  background-color: #fff;
  border: 1px dashed gray;
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 500px;
  height: 150px;
  margin-bottom: 15px;
}

.upload-cmp:hover {
  background-color: #FFD50033;
  border-color: #FFD500;

  .title {
    color: #FFD500;
  }
}

.upload-cmp .img-remove {
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -15px;
  right: -20px;
  z-index: 5;
  border-radius: 50%;
  background-color: #e74c3c;
  padding: 2px;
}

.upload-cmp .img-remove .mdi-icon {
  color: #fff;
}


.upload-cmp .title {
  text-align: center;
  width: 50%;
  font-size: 18px;
}

.upload-cmp > .file-upload {
  position: absolute;
  width: 100%;
  height: 100% !important; /* todo remove important later when we refactor css */
  max-height: 100% !important; /* todo remove important later when we refactor css */
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
}


.img-preview {
  flex: 1;
  width: 50vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.img-preview .img-container {
  width: 250px;
  height: 120px;
  position: relative;
  margin: 10px;
  border: 1px solid gray;
  border-radius: 15px;

  img {
    object-fit: contain;
  }

  .img-remove {
    cursor: pointer;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0px;
    right: 0px;
    z-index: 5;
    border-radius: 50%;
    background-color: #e74c3c;
    padding: 2px;

    .mdi-icon {
      color: #fff;
    }
  }
}
