@import "../../styles/variables";

.top-navigation {
    width: 100%;
    a {
      flex: 1;
      align-items: center;
      color: $color-white;
      word-break: break-word;
      white-space: pre;
      cursor: pointer;
    }

    li {
      display: flex;
      height: 50px;
      width: 100%;
      justify-content: left;
      align-items: center;
      padding: 0 14px;
      word-break: break-word;
      white-space: pre;
      font-size: 16px;

      &.active {
       color: $color-yellow;
       background-color: rgba(255, 255, 255, .1)
      }

      &:hover {
        cursor: pointer;
        &:not(.horizontal) {
          background-color: rgba(255, 255, 255, .1)
        }
      }
      .nav-text {
        opacity: 1;
      }
      span {
        margin-left: 10px;
        line-height: 1.13;
      }

      svg {
        overflow: visible;
        min-width: 28px;
        height: 28px;
      }
    }
}
