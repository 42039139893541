@import "../../styles/variables";

.login-container {
  display: flex;
  height: 100vh;
  width: 100%;

  .step_number_2{
    .icon-container{
      background: #95c11e !important;

      svg {
        color: #fff;
        fill: #fff !important;
      }
    }

    .step-label{
      color: #95c11e !important;
    }
  }

  .step_information{
    .icon-container{
      background: #ffd500 !important;
    }

    .step-label{
      color: #ffd500 !important;
    }
  }

  .step_information_2{

    .icon-container{
      background: #95c11e !important;

      svg {
        color: #fff;
        fill: #fff !important;
      }
    }

    .step-label{
      color: #95c11e !important;
    }
  }

  .invalid{
    position: absolute;
    right: 0;
    bottom: -25px;
    color: $color-damage-red;
    font-size: 15px;
  }

  .forgot-eye{
    position: relative;
    img{
      position: absolute;
      bottom: 18px;
      right: 11px;
      &:hover{
        cursor: pointer;
      }
    }
  }
  .left-block {
    position: relative;
    flex:  0 0 33%;
    background-color: $color-blue-middle;
    > svg {
      position: absolute;
      right: 30px;
      bottom: 15px;
      height: 60%;
      width: auto;
      z-index: 5;
    }

    &.row{
      flex:  0 0 100%;
    }

    .icons-circle {
      position: relative;
      padding-bottom: 65%;
      margin-right: 20%;
      margin-left: 15%;
      margin-top: 20%;
      .dashed-circle {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 3px dashed white;
      }
      .icon-container{
        transform: translate(-50%, 50%);
        background-color: $color-input-background;
        position: absolute;
        border-radius: 50%;
        padding: 12px;
        svg{
          display: block;
          width: 52px;
          height: auto;
          fill: $color-blue-dark;
        }
      }
    }

    .stepper {
      display: flex;
      flex-direction: column;
      margin: 10%;
      .step {
        display: flex;
        align-items: center;
        .icon-container {
          background-color: $color-input-background;
          border-radius: 50%;
          padding: 14px;
          margin-right: 16px;
          z-index: 10;
          svg {
            width: 48px;
            height: auto;
            display: block;
            fill: $color-blue-dark;
          }
        }
        .step-label {
          font-size: 30px;
          font-weight: 300;
          line-height: 1.17;
          color: $color-input-background;
          z-index: 10;
        }
        &:first-of-type .icon-container{
          background: #ffd500;
        }
        &:first-of-type .step-label{
          color: #ffd500;
        }
      }
      .dashed-line {
        height: 104px;
        width: 40px;
        border-right: 3px dashed $color-input-background;
        &:after {
          content: "";
          width: 1px;
          background-color: transparent;
          border-left: 2px dashed rgba(red, .15);
        }
      ;
      }
    }
  }
  .rectangle {
    width: 0;
    height: 0;
    border-top: 72vh solid transparent;
    border-bottom: 28vh solid transparent;
    border-left: 21vh solid $color-blue-middle;
  }
  .right-block {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    overflow: hidden;
    color: $color-blue-middle;
    .right-title {
      font-size: 45px;
      line-height: 1.6;
      color: $color-blue-dark;
      font-weight: 500;
      text-align: center;
    }

    .pop-up .input > input {
      padding: 15px 15px 15px 10px
    }

    .form-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      //margin-left: 5%;
      margin-right: 15%;

      .cmp-input .cmp-input-field{
        padding: 25px 25px 25px 10px;
      }

      .input {
          margin:0;
        input{
        padding: 15px 15px 15px 10px;
      }
        }
        //margin-top: 50px;
        //input {
        //  font-size: 20px;
        //  height: 35px;
        //}
      //}
      .forgot-password {
        font-size: 14px;
        line-height: 1.21;
        text-decoration: underline;
        margin-top: 5px;
        margin-bottom: 78px;
        cursor: pointer;
      }
      .actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        .button {
          max-width: 360px;
          margin-bottom: 14px;
        }
        .sign-up {
          span {
            font-size: 20px;
            font-weight: 500;
          }
          .sign-up-link {
            cursor: pointer;
            position: relative;
            display: inline-block;
            &:after{
              content:'';
              position: absolute;
              left: 0;
              bottom: 1px;
              width: 100%;
              height: 1px;
              background: #00587f;
            }
          }
        }
      }
    }
    .privacy-policy {
      overflow: auto;
      max-height: 100vh;
      padding: 40px 44px 80px 32px;
      .privacy-text {
        a{
          font-size: 20px;
          line-height: 1.15;
          color: #00587f;

          &:hover{
            text-decoration: underline;
            text-decoration-color: #2D7695;
            cursor: pointer;
            color: #00587f
          }
        }

        padding: 35px;
        h2, p, li {
          font-size: 20px;
          line-height: 1.15;
        }
        h2 {
          margin-top: 40px;
          margin-bottom: 10px;
          font-size: 24px;
          font-weight: 500;
          font-style: italic;
          &.blue {
            margin-top: 20px;
            font-size: 20px;
            font-style: normal;
          }
        }
        p {
          margin: 14px 0;
        }
        ul {
          list-style-type: disc;
          padding-left: 3ch;
        }
      }
    }

    .customer-number {
      display: flex;
      flex-direction: column;
      align-items: center;

      &_inputs input::selection {
        background: #EEF5F8; /* Safari */


      }
      &_inputs input::-moz-selection {
        background: #EEF5F8; /* Firefox */


      }

      .right-title {
        margin-bottom: 52px;
      }
      .button-arrow{
        margin-top: 7px;
      }
      .number-label {
        margin-bottom: 30px;
      }
      .number-input {
        margin-bottom: 44px;
        input {
          border-top: none;
          border-right: none;
          border-left: none;
          border-bottom: 1px solid $color-blue-middle;
          width: 2ch;
          font-family: 'Maven', sans-serif;
          font-size: 45px;
          margin-right: 16px;
          text-align: center;
          color: $color-blue-dark;
        }
      }
      .notice-block {
        margin-bottom: 87px;
        margin-top: 92px;
        max-width: 540px;
        padding: 9px 8px;

        .notice-container {
          padding-left: 26px;
          padding-right: 26px;

          .notice-text {
            text-align: center;
            line-height: 18px;
            font-size: 16px;

            &:first-child {
              margin-top: -20px;
              margin-bottom: 20px;
            }
          }
        }
      }
      .button {
        max-width: 360px;
        svg {
          width: 40px;
        }
      }

      &_inputs{
        display: flex;
        justify-content: center;
      }

      .button_customer{
        margin-left: auto;
        margin-right: auto;
        display: block;
      }

      &_inputs{
        position: relative;
      }

      &_error{
        position: absolute;
        bottom: -26px;
        font-size: 13px;
        color: $color-damage-red;
      }
    }
  }
  &.popup-over:after{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: #0000003d;
    z-index: 90;
    visibility: visible;
  }
}

.login-container {

  .personal-info{
    form .button-container{
      button{
        position: static;
        color: #fff;
        left: unset;
        right: unset;
        bottom: unset;
        transform: unset;
        &:hover{
          cursor: pointer;
        }
      }
    }
  }

  .select-box{
    margin-bottom: 25px;
    font-size: 20px;
    color: #00273c;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    padding-top: 6px;
    height: 50px;
    border-radius: 25px;
    background-color: $color-input-background;
    padding-left: 10px;
    padding-right: 10px;
    &:hover{
      cursor: pointer;
      z-index: 9;
    }

    svg{
      color: $color-yellow;
    }


    &:hover{
      cursor: pointer !important;
    }

    > div{
      border: unset;
      background-color: $color-input-background;

      &:hover{
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .rectangle {
    display: none;
  }

}
