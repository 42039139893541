.upload-cmp {
  background-color: #fff;
  border: 1px dashed gray;
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 500px;
  height: 150px;
}

.upload-cmp:hover {
  background-color: #FFD50033;
  border-color: #FFD500;
  .title {
    color: #FFD500;
  }
}

.upload-cmp .img-remove .mdi-icon {
  color: #fff;
}


.upload-cmp .title {
  text-align: center;
  width: 50%;
  font-size: 18px;
}

.img-preview {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-preview img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}
