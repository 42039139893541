.employee-time-tracking-filter-container {
  padding: 20px;

  .total-hours {
    color: #00587F;
    font-size: 16px;
    font-weight: 500;
  }

  .between {
    margin-right: 10px;
  }
}
