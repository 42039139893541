@import "../../../styles/variables";

.header-ticket-row {
  .ticket-heading-title {
    font-size: 22px;
    color: #ffd500;
    margin-left: 15px;
    margin-bottom: 40px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
