@import "../../../../styles/variables";

.tags-list {
  width: 120px;
  padding-top: 4px;
  font-size: 13px!important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: $color-blue-light;
}
