button.react-datepicker__navigation {
  height: 15px!important;
}
.cmp-date-picker {
  width: 100%;
  .react-datepicker-wrapper {
    display: flex;
  }
  .calendar {
    background: #eef5f8;
    display: flex;
    padding: 5px 10px;
    color: #00273C;
    font-size: 15px;
    justify-content: space-between;
    border-radius: 5px;
    .calendar-icon {
      font-size: 16px;
    }
    svg{
      cursor: pointer;
    }
  }

  .picker-label {
    padding-bottom: 10px;
    margin-left: 10px;
  }

  &.marginLeft {
    margin-left: 20px;
    margin-right: 8px;
  }

}
