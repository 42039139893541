@import "../../../styles/variables";

.page-header {
  display: flex;
  flex-direction: column;
  padding: 0;
  background: linear-gradient(20deg, $color-blue-dark 0%, $color-blue-dark 25%, $color-marker-passenger-car 100%);

  .version{
    display: none;
  }

  .license-plate{
    width: unset;
  }

  .back-container {
    display: flex;
    flex-basis: auto !important;
    flex-grow: 0 !important;

    .back-link {
      display: flex;
      align-items: center;
      padding: 0 24px;
      margin-top: 10px;
      svg, span {
        color: $color-white;
      }

      svg {
        margin-right: 22px;
      }

      span {
        font-size: 16px;
        line-height: 1.13;
      }

      &:hover {
        cursor: pointer;

        svg, span {
          color: $color-yellow;
        }
      }
    }
  }

  .user-info-block {
    margin-right: 56px;
    align-self: flex-end;
  }
}

@media screen and (max-width: $sm) {
  .page-header {

    .back-container {
      display: none;
    }

    .navbar > .user-info-block {
      margin-right: 0;
      padding: 8px;
      align-self: center !important;
    }

    .navigation ul li span {
      display: none;
    }
  }
}
