.single-trip-container  {
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
  flex-direction: column;
  color: #00587F;
  position: relative;
  margin-bottom: 20px;


  .time-margin {
    margin-top: 11px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .left-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width:150px;
    span{
      margin-left: 5px;
    }
    p:nth-child(2){
      margin-left: 5px;
    }
    p:nth-child(3) {
      margin-left: 5px;
    }
  }

  .right-text {
    margin-left: 10px;
    width: 100%;
    p:first-child {
      font-weight: 500;
    }
    p:last-child {
      font-weight: 500;
    }

  }
}
