.rec-carousel-wrapper .rec-carousel-item{
  display: flex;
  align-items: center;
}


.rec-carousel-wrapper  button.rec-arrow {
  width: 42px !important;
  height: 42px;
  background: #00587F;
  border: unset;
  max-width: 42px !important;
  padding: 0;
  font-size: 16px;
  line-height: 42px;
  min-width: 42px !important;
}

.rec-carousel-wrapper  button.rec-arrow-left{
  color: #00587F !important;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 9999;

  &:after{
    content: '\2192';
    position: absolute;
    color: #fff !important;
    left: 5px;
    font-size: 46px;
    max-width: 23px;
    overflow: hidden;
    display: flex;
    top: 5px !important;
    justify-content: flex-end;
    transform: rotate(180deg);
  }
  &:hover{
    opacity: 0.8;
    background-color: #00587F !important;
  }

  &:focus{
    background-color: #00587F !important;
  }
}

.rec-carousel-wrapper  button.rec-arrow-right{
  transform: translate(0, -50%);
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 9999;
  color: #00587F !important;

  &:after{
    content: '\2192';
    position: absolute;
    color: #fff !important;
    left: 16px;
    font-size: 46px;
    max-width: 23px;
    overflow: hidden;
    display: flex;
    top: -5px !important;
    justify-content: flex-end;
  }
  &:hover{
    opacity: 0.8;
    background-color: #00587F !important;
  }

  &:focus{
    background-color: #00587F !important;
  }
}
