.trips-bottom {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 10px;

  .margin-top-8 {
    margin-top: 8px;
  }
}

.trip {
  .left-section {
    display: flex;
    max-width: 200px;
    flex-direction: row;
    .trip-info {
      margin-left: 15px;
      p{
      font-size: 16px;
      }
    }
    .multi-user{
    display: flex;
      align-items: center;
      .user-avatar{
      svg{
        background-color: #00587F;
      }
    }
    }
  }
  .trip-address {
    width: 210px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
