@import "../../../styles/variables";

.cmp-input {
  color: $color-input-text;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  .cmp-input-label {
    font-size: 14px;
    line-height: 1.21;
    margin-bottom: 10px;
    margin-left: 10px;
  }

  > p {
    font-size: 14px;
    color: $color-blue-middle;
  }

  .cmp-input-field{
    width: 100%;
    background-color: #eef5f8;
    border: none;
    font-size: 16px;
    font-family: 'Maven', sans-serif;
    padding: 10px;
    border-radius: 5px;
    box-sizing: border-box;
    max-height: 30px;
  }
  .cmp-input-error {
    color: red;
    margin-left: 10px;
    margin-top: 5px;
  }
}
