@import "../../../styles/variables";

.chart-container {
  width: 100%;
  height: 30vw;
  max-width: 100vw;
  min-height: 30vw;
  border-radius: 3px;
}

.legend-container {
  display: flex;
  flex-direction: column !important;
  height: calc(30vw - 5px);
  overflow-y: auto;

  .mileage-legend-item {
    max-height: 30vw;
    display: flex;
    flex: 1;
    align-items: flex-end;
    border-left: 10px solid $color-green;
    padding: 10px;


    .vehicle-info {
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 100%;
      align-items: flex-start;
      justify-content: center;
    }

    .legend-img-container {
      flex: 1;
      display: flex;
      justify-content: flex-end;

      img {
        object-fit: fill;
      }
    }

  }
}

@media (max-width: 1200px) {
  .chart-container {
    height: 40vw;
  }

}
