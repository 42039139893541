@import "../../../styles/variables";

.edit-user-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  max-width: 100% !important;
  flex-wrap: wrap;

  input[type=file], /* FF, IE7+, chrome (except button) */
  input[type=file]::-webkit-file-upload-button { /* chromes and blink button */
    cursor: pointer;
  }



  .delete_account{
    text-align: center;
    margin-left: 9px;
    max-width: 346px;

    .img-container{
      z-index: 400;
      margin-left: auto;
      margin-right: auto;
    }

    &_main{
      position: relative;
    }

    .img-row{
      position: relative;
      margin-bottom: 3px;
      max-width: 149px;
      margin: 0 auto;
      position: absolute;
      transform: translate(-50%, -10%);
      left: 50%;
      top: 50%;

      &:after{
        content: '';
        position: absolute;
        background: #94B9C9;
        width: 135px;
        height: 135px;
        border-radius: 50%;
        z-index: 2;
        left: -10px;
        top: -10px;
      }

      &:before{
        content: '';
        position: absolute;
        background: #C2D7E0;
        width: 146px;
        height: 146px;
        border-radius: 50%;
        z-index: 2;
        left: -18px;
        top: -18px;
      }
    }

    .img-container{
      width: 115px;
      height: 115px;
    }


    &_line{
      background: #00273C;
      border-radius: 5px;
      height: 12px;
      width: 100%;
      max-width: 190px;
      position: relative;
      margin: 0 auto;
      margin-top: 220px;
    }

    &_circle{
      background: #FFD500;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      display: inline-block;
      position: absolute;
      left: 4px;
      top: 3px;
    }

    &_range{
      background: #FFD500;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      display: inline-block;
      position: absolute;
      left: 14px;
      top: 4px;
    }

    &_box{
      width: 190px;
      height: 217px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0 auto;
    }

    &_st{
      height: 190px;
      width: 2px;
      background: #fff;
      z-index: 10;
    }

    &_title{
      font-size: 16px;
      color: $color-black;
      text-align: center;
      font-weight: 500;
      max-width: 260px;
      margin: 0 auto;
      margin-bottom: 30px;
      line-height: 20px;

      .img-container {
        margin: 0 auto;
      }
    }
  }
}
